import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import CurrencyDropdown from '../../../shared/CurrencyDropdown';
import Checkbox, { SliderSize } from '../../../shared/form-control/Checkbox';
import ActionEditorBase from '../ActionEditorBase';
import ShowInResourceView from '../ShowInResourceView';

const CurrencyInputResourceEditor: FC<EditorProps> = (props) => {
  const { action, patchData } = props;
  const { data } = action;
  const { currencyCode = 'USD', allowChange = false } = data || {};

  const { t } = useTranslation('form-builder');

  const onAllowChange = useCallback(
    (value: boolean) => {
      patchData({ allowChange: value });
    },
    [patchData],
  );

  const onCurrencyChange = useCallback(
    (value: string) => {
      patchData({ currencyCode: value });
    },
    [patchData],
  );

  return (
    <div data-cy="text-input-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-dpm-12">{t('action-properties.currency-input.symbol')}</div>

        <div className="w-[150px]">
          <CurrencyDropdown selectedCode={currencyCode} onChange={onCurrencyChange} align="right" />
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-dpm-12">{t('action-properties.currency-input.allow-change')}</div>
        <Checkbox slider sliderSize={SliderSize.S} value={allowChange} onChange={onAllowChange} className="my-4" />
      </div>

      <ShowInResourceView {...props} />
    </div>
  );
};

export default CurrencyInputResourceEditor;
